<template>
  <section class="allwrap min-w1400">
     <div>
        <article class="content_wrap">
          <table>
             <colgroup>
                 <col width="22%">
                 <col width="28%">
                 <col width="22%">
                 <col width="28%">
             </colgroup>
             <thead>
                 <tr>
                    <th colspan="4">{{ $t('company.companyInfo') }}</th>
                 </tr>
             </thead>
             <tbody>
                <tr>
                   <td>{{ $t('company.companyName') }}</td>
                   <td><input type="text" ref="memName" v-model="regInfo.memName" /></td>
                   <td>{{ $t('company.class') }}</td>
                   <td>
                      <select class="reqMember" name="partnerType" v-model="regInfo.partnerType">
                        <option value="master" v-if="siteInfo.partnerType === 'senior'">{{ $t('company.master') }}</option>
                        <option value="agency" v-if="siteInfo.partnerType === 'master'">{{ $t('company.agency') }}</option>
                      </select>
                   </td>
                </tr>
                 <tr>
                    <td>{{ $t('company.id') }}</td>
                    <td><input type="text" ref="memId" v-model="regInfo.memId" /></td>
                    <td>{{ $t('company.contact') }}</td>
                    <td><input type="text" v-model="regInfo.memPhone" /></td>
                 </tr>
                 <tr>
                    <td>{{ $t('company.pw') }}</td>
                    <td><input type="password" v-model="regInfo.memPass" /></td>
                    <td>{{ $t('company.pwCheck') }}</td>
                    <td><input type="password" v-model="passCheck" /></td>
                 </tr>
             </tbody>
          </table>

          <table>
             <colgroup>
                 <col width="22%">
                 <col width="28%">
                 <col width="22%">
                 <col width="28%">
             </colgroup>
             <thead>
                <tr>
                   <th colspan="4">{{ $t('company.ptBalance') }}</th>
                </tr>
             </thead>
             <tbody>
                <tr>
                   <td>{{ $t('company.ptLimit') }}</td>
                   <td>
                      <div class="onlyNum">
                        <input type="number" id="maxCreditInput" v-model="regInfo.maxCredit" />
                        <label for="maxCreditInput">{{ numberWithCommas(regInfo.maxCredit) }}</label>
                      </div>
                   </td>
                   <td>{{ $t('company.feeRate') }}</td>
                   <td>
                      <select v-model="regInfo.creditRate">
                        <template v-for="(n, index) in this.creditRate" :key="index">
                         <option :value="n" :selected="Number(n) === Number(this.siteInfo.creditRate)">{{ n }}</option>
                        </template>
                     </select>
                   </td>
                </tr>
             </tbody>
          </table>

          <table>
             <thead>
                <tr>
                   <th>
                      <div class="fx-col-sbw">
                        {{ $t('company.gamePTFee') }}
                        <div>
                          <input type="number" class="mr-5" @change="totalRateLimit" v-model="controlRate" />
                          <button type="button" class="btn-layout btn-blue" @click="rateAllChange">{{ $t('common.allApply') }}</button>
                        </div>
                      </div>
                   </th>
                </tr>
             </thead>
             <tbody>
                <tr>
                   <td colspan="2">
                      <div class="page-content s-content-wrap wrapper-contents itemz">
                       <template v-for="(item, index) in rateList" :key="item.gameType">
                         <div class="s-content">
                           <div class="title">
                             <span>{{ item.gameName }}</span>
                           </div>
                           <div class="content">
                             <div class="rollcount">
                               <button type="button" @click="decrease(index)">-</button>
                               <input type="number" @change="rateLimit(index)" v-model="item.pointRate" />
                               <button type="button" @click="increase(index)">+</button>
                             </div>
                           </div>
                         </div>
                       </template>
                     </div>
                   </td>
                </tr>
             </tbody>
          </table>
          <div class="center">
            <btn-regist @click="regist" />
          </div>

        </article>

     </div>
  </section>
</template>

<script>
import lodash from 'lodash'
import subTitle from '@/components/main/PageSubTitle'
import { getSiteData } from '@/libs/auth-helper'
import BtnRegist from '@/components/ui/BtnRegist'
import { getCode } from '@/api/common'
import { companyDetail, companyRegist } from '@/api/company'
import { memberDetail } from '@/api/member'
import { GAME_INFO_LIST } from '@/libs/constants.js'
import { isValidUserId } from '@/libs/utils'

export default {
  name: 'Create',
  components: {
    subTitle,
    BtnRegist
  },
  data () {
    return {
      controlRate: '0.00',
      checkedToggle: false,
      subToggle: false,
      nationList: [],
      commissionDisplay: 'all',
      commissionObj: {
        group: [],
        commissionList: []
      },
      rateTypeDefault: '',
      rateType: [],
      betLimitList: [],
      regInfo: {
        memId: '',
        memPass: '',
        memName: '',
        memPhone: '',
        creditRate: '0',
        maxCredit: '',
        insuredYn: 'N',
        memBlackYn: 'N',
        partnerType: 'agency'
      },
      passCheck: '',
      siteInfo: {},
      rateList: [],
      gameList: [],
      maxCreditComma: '',
      creditRate: []
    }
  },
  methods: {
    creditRoop () {

    },
    async deduplicate (memId) {
      // const siteId = this.siteInfo.siteId;
      const req = {
        memId
      }

      const res = await memberDetail(req)
      if (!res.data.memberDetail) {
        return true
      } else {
        return false
      }
    },
    setRateList () {
      this.setRateListDefault()
    },
    setRateListDefault () {
      for (const loop of this.gameListDefault) {
        for (const item of loop.child) {
          if (item.useYn === 'N') {
            continue
          }
          // console.log(item.groupCode);
          const model = lodash.cloneDeep(this.rateModel)
          model.gameType = item.code
          model.gameName = item.codeName
          model.groupCode = item.groupCode
          model.loop = Number(model.maxPointRate) / this.rateIncrease
          this.rateList.push(model)
        }
      }
      // console.log(this.rateList);
      this.originRate = lodash.cloneDeep(this.rateList)
    },
    totalRateLimit () {
      const n = Number(this.controlRate).toFixed(2)
      if (n >= 100) {
        this.controlRate = (100).toFixed(2)
      } else if (n < 0) {
        this.controlRate = (0).toFixed(2)
      } else {
        this.controlRate = Number(this.controlRate).toFixed(2)
      }
    },
    rateAllChange () {
      const rateList = this.rateList
      for (const item of rateList) {
        item.pointRate = this.controlRate
      }
    },
    rateLimit (idx) {
      const n = Number(this.rateList[idx].pointRate).toFixed(2)
      if (n >= 100) {
        this.rateList[idx].pointRate = (100).toFixed(2)
      } else if (n < 0) {
        this.rateList[idx].pointRate = (0).toFixed(2)
      } else {
        this.rateList[idx].pointRate = Number(this.rateList[idx].pointRate).toFixed(2)
      }
    },
    increase: function (idx) {
      if (Number(this.rateList[idx].pointRate) === 100) {
        return
      }
      this.rateList[idx].pointRate = (Number(this.rateList[idx].pointRate) + 0.05).toFixed(2)
    },
    decrease: function (idx) {
      if (Number(this.rateList[idx].pointRate) <= 0) {
        return
      }
      this.rateList[idx].pointRate = (Number(this.rateList[idx].pointRate) - 0.05).toFixed(2)
    },
    getSiteInfo () {
      const siteInfoCookie = getSiteData()
      if (siteInfoCookie) {
        const siteInfo = JSON.parse(siteInfoCookie)
        return siteInfo
      }
    },
    // setMaxCredit(e) {
    //   const code = e.keyCode;
    //   if ((code > 47 && code < 58) || e.ctrlKey || e.altKey || code === 8 || code === 9 || code === 46) {
    //     if (code > 47 && code < 58) {
    //       const split = e.target.value.split(",");
    //       let text = "";
    //       for (const item of split) {
    //         text += "" + item;
    //       }
    //       console.log(text);
    //       e.target.value = this.numberWithCommas(text);
    //     }
    //     return;
    //   }
    //   e.preventDefault();
    // },
    async regist () {
      const req = lodash.cloneDeep(this.regInfo)
      console.log(req, this.regInfo)
      // const overlapChk = await this.deduplicate(req.memId)
      // if (!overlapChk) {
      //   alert('중복된 아이디 입니다.')
      //   this.$refs.memId.focus()
      //   return
      // }

      if (!req.memName) {
        alert('회사명을 입력해주세요')
        return false
      }

      if (!isValidUserId(this.regInfo.memId)) {
        alert('하부업체 아이디는 최소 5자이상 최대 20 자이하로 영문+숫자로 작성해주세요.')
        return
      }

      // if (req.maxCredit > 200000000) {
      //   alert("최대 크레딧 한도를 확인해주세요.");
      // }
      req.rateList = []
      const rateList = lodash.cloneDeep(this.rateList)
      for (const item of rateList) {
        if (item.useYn) {
          item.useYn = 'Y'
        } else {
          item.useYn = 'N'
        }
        if (item.betYn) {
          item.betYn = 'Y'
        } else {
          item.betYn = 'N'
        }
        const rateModel = {
          gameType: item.gameType,
          pointRate: item.pointRate,
          useYn: item.useYn,
          betYn: item.betYn
        }
        req.rateList.push(rateModel)
      }
      const res = await companyRegist(req)
      if (res.resultCode === '0') {
        alert('하부업체 등록완료')
        this.$router.push({ name: 'companyList' })
      } else {
        alert('하부업체 등록 실패. ', res.resultMessage)
      }
    },
    async getUpperCompany () {
      const siteInfo = this.getSiteInfo()
      const reqDetail = {
        siteId: siteInfo.siteId,
        memId: siteInfo.siteId
      }
      const loginCompanyDetail = await companyDetail(reqDetail)

      return await loginCompanyDetail.data.memberDetail
    }
  },
  // watch: {
  //   regInfo: {
  //     handler(target) {
  //       console.log(target.maxCredit);
  //       if (target.maxCredit >= 200000000) {
  //         target.maxCredit = 200000000;
  //       }
  //     },
  //     deep: true
  //   }
  // },
  async created () {
    this.siteInfo = await this.getUpperCompany()
    const pType = this.siteInfo.partnerType
    if (pType) {
      switch (pType) {
        case 'senior': {
          this.regInfo.partnerType = 'master'
          break
        }
        case 'master': {
          this.regInfo.partnerType = 'agency'
          break
        }
      }
    }

    this.regInfo.creditRate = this.siteInfo.creditRate

    let credit = Number(this.siteInfo.creditRate)
    while (credit <= 30) {
      this.creditRate.push(parseFloat(credit.toFixed(1)))
      credit = parseFloat((credit + 0.1).toFixed(1)) // 소수점 올림 (첫째자리까지 출력)
    }

    await this.setGameList()
    this.setRateList()
  }
}
</script>

<style scoped>
.allwrap  {max-width: 1440px;margin: 0 17px;}
.content_wrap {
    display: flex;
    flex-direction: column;
    gap: 20px;
    box-sizing: border-box;
    width: calc(50% - 20px);
    padding: 13px;
    background-color: #fff;
    box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #b1b1b1;
}
.content_wrap table {
    font-size: 12px;
    color: #585963;
}
.content_wrap table thead {
    width: 100%;
    box-sizing: border-box;
    border-radius: 3px 3px 0 0;
    border: solid 1px #b1b1b1;
    background-color: #f8f8f8;
    font-size: 14px;
    color: #3d3d3d;
}
.content_wrap table th {
    padding: 12px 15px;
    text-align: left;
}
.content_wrap table td {
    padding: 8px 14px;
    border: 1px solid #b1b1b1;
    background-color: #fff;
}
table {
  width: 100%;
}

.content-wrap {
  margin-bottom: 40px;
}

.content {
  font-size: 0.75em;
}

.content td {
  padding: 4px 10px;
  height: 35px;
  box-sizing: border-box;
  border-bottom: solid 1px #ddd;
}

.input-title {
  font-weight: 700;
  text-align: left;
  width: 9%;
}

.input-value {
  width: 20%;
}

.inner {
  display: flex;
  align-items: center;
}

/* input {
  border: 1px solid #18698b;
  border-radius: 3px;
} */
.mainId {
  margin-right: 5px;
}

.innerWrap {
  padding: 4px 10px;
  height: 35px;
  max-height: 35px;
  box-sizing: border-box;
  display: flex;
  align-content: center;
  border-bottom: solid 1px #ddd;
}

/* 라디오 */
.box-ui-radio {
  font-size: 1em;
  display: flex;
  justify-content: center;
  margin-right: 10em;
}

.box-ui-radio:last-child {
  margin-right: 0;
}

input[type="radio"] {
  display: none;
}

input[type="radio"]+label {
  display: flex;
  align-items: center;
}

input[type="radio"]+label>.circle {
  display: block;
  width: 17px;
  height: 17px;
  box-sizing: border-box;
  border: 2px solid #394357;
  border-radius: 100%;
  margin-right: 5px;
}

input[type="radio"]:checked+label>.circle {
  border: 5px solid #394357;
}

/* 체크박스 */
.box-ui-check {
  font-size: 1em;
  display: flex;
  justify-content: center;
}

input[type="checkBox"] {
  display: none;
}

input[type="checkbox"]+label {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

input[type="checkbox"]+label>i {
  border: 2px solid #394357;
  background: #fff;
  border-radius: 5px;
  padding: 2px;
  font-size: 10px;
  color: #fff;
  margin-right: 4px;
}

input[type="checkbox"]:checked+label>i {
  background: #394357;
}

.hidenContent {
  display: none;
  align-items: center;
  width: fit-content;
}

.hidenContent.show {
  display: flex;
}

.subContent-wrapper {
  width: 100%;
  height: auto;
  max-height: unset;
  padding: 2px 5px;
}

.subContent-wrap {
  width: 100%;
}

.subContent-wrap .subTitle {
  font-size: 1.2em;
  font-weight: 800;
  background: #e9a198;
  color: #fff;
  padding: 8px 10px;
  position: relative;
}

.subContent {
  max-height: 0;
  overflow: hidden;
}

.subContent.open {
  padding: 5px 5px;
  max-height: unset;
}

.subContent th,
.subContent td {
  border: 1px solid #fff;
  padding: 4px 10px;
  height: 35px;
  box-sizing: border-box;
}

.subContent th {
  background: #ffe5de;
  color: #000;
  font-weight: 700;
}

th.subTitleHead {
  background: transparent;
  color: #000;
  text-align: left;
  border-bottom: solid 1px #ddd;
}

.subTable-title {
  background: #ffe5de;
  font-weight: 800;
  padding: 8px 8px;
}

.btn-subConShow {
  position: absolute;
  border: 2px solid #df4946;
  border-radius: 3px;
  padding: 4px;
  background: #df4946;
  color: #fff;
  font-size: 0.8em;
  transform: translateY(-50%);
  top: 50%;
  right: 8px;
  cursor: pointer;
}

.btn-subConShow:hover {
  background: #fff;
  color: #df4946;
}

.textCenter {
  text-align: center;
  font-weight: 700;
  padding: 4px 0;
}

.remark {
  font-weight: 800;
  color: red;
}

.notice {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  color: red;
}

.notice i {
  font-weight: 800;
  font-size: 1.5em;
  margin-right: 10px;
}

.notice>span {
  font-size: 1em;
  font-weight: 800;
}

.s-content {
  border: 0;
}

/* title select */
.title-select-wrap {
  font-size: 12px;
  margin: 5px;
  min-width: 75px;
  max-width: 75px;
  display: flex;
  flex-direction: column;
  border: 1px solid #afafaf;
}

.title-select-title {
  padding: 5px 4px;
  box-sizing: border-box;
  text-align: center;
  background: #298cb3;
  color: #fff;
  font-weight: 700;
  border-radius: 6px 6px 0 0;
}

.title-select-content {
  border: 1px solid #9e9e9e;
  border-top: 0;
  border-radius: 0 0 6px 6px;
}

.title-select-item {
  box-sizing: border-box;
  padding: 4px;
  font-size: 1em;
  width: 100%;
  border: 0;
}

.center button {
    width: 65px;
    height: 22px;
    border-radius: 2px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #808080;
    background-image: linear-gradient(to bottom, #fff 19%, #f6f3e9);
    color: #252930;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.item-content input {
  width: 100%;
  max-width: 202px;
}

.item-content select {
}

.userInfo-select {
  font-weight: 500;
  padding: 2px 5px;
  height: 21px;
  width: 100%;
}

.s-content>.content {
  display: flex;
  font-size: 1em;
  height: calc(56px - 26px);
}

.s-content-wrap {
  max-height: 370px;
}

.s-content {
  flex: 0 1 16%;
  border: 0;
  margin: 10px;
}

.s-content>.title {
  margin-bottom: 0;
  background: #353535;
  white-space: nowrap;
  color: #fff;
}

.itemz .s-content {
  /*flex: 0 0 9.6%;*/
  max-width: unset;
}

.itemz .s-content .content select {
  border-top: 0;
  border-radius: 0 0 6px 6px;
  height: 30px;
  text-align: center;
}

.rollcount {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid #9e9e9e;
  border-top: 0;
}

.rollcount button {
  color: #676767;
  font-weight: bold;
  font-size: 1.2em;
  background: #fff;
}

.rollcount span {
  font-weight: bold;
  color: #212121;
  font-size: 1em;
}

.center {
  text-align: center;
}

.rollcount>input[type="number"] {
  width: 45px;
  text-align: center;
  border: none;
}

.onlyNum {
  position: relative;
}

#maxCreditInput {
  position: relative;
  background-image: none;
  background-color: transparent;
  color: transparent;
  border: 0;
  z-index: 1;
}
#maxCreditInput::selection{
  background:none;
}
#maxCreditInput+label {
  color: #252930;
  font-size: 11px;
  height: 22px;
  padding: 4px 9px;
  box-sizing: border-box;
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #808080;
  background-image: linear-gradient(to bottom, #fff 19%, #e9f0f6);
  width: 100%;
  max-width: 190px;
  position: absolute;
  left:0;
  top:0;
  display:flex;
  align-items: center;
  justify-content: flex-end;
}
</style>
